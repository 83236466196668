import * as Types from '../../common/types/graphql-types';

import { gql } from '@apollo/client';
import { BaseTrackFragmentDoc } from '../fragments/baseTrack';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateTrackMutationVariables = Types.Exact<{
  title: Types.Scalars['String'];
  project: Types.Scalars['UUID'];
}>;


export type CreateTrackMutation = { __typename?: 'Mutation', createTrack: { __typename?: 'TrackMutationResponse', track: { __typename?: 'Track', id: string, index: number, title: string, album?: { __typename?: 'Album', id: string, tracks: { __typename?: 'TrackPage', results: Array<{ __typename?: 'Track', id: string, index: number, title: string, hasPendingRevisions: boolean, canEdit: boolean, canDelete: boolean, updatedAt: number, status?: Types.RevisionStatus | null | undefined, primaryRevision?: { __typename?: 'Revision', id: string, canDownload: Types.CanDownload, isProcessed: boolean, original?: { __typename?: 'AudioData', mp3?: string | null | undefined, wav?: string | null | undefined } | null | undefined, master?: { __typename?: 'AudioData', mp3?: string | null | undefined, wav?: string | null | undefined } | null | undefined } | null | undefined, album?: { __typename?: 'Album', id: string, title: string, artist?: string | null | undefined, artwork?: string | null | undefined, canUpload: boolean } | null | undefined }> } } | null | undefined } } };


export const CreateTrackDocument = gql`
    mutation CreateTrack($title: String!, $project: UUID!) {
  createTrack(input: {title: $title, album: $project}) {
    track {
      id
      index
      album {
        id
        tracks {
          results {
            ...BaseTrack
          }
        }
      }
      title
    }
  }
}
    ${BaseTrackFragmentDoc}`;
export type CreateTrackMutationFn = Apollo.MutationFunction<CreateTrackMutation, CreateTrackMutationVariables>;

/**
 * __useCreateTrackMutation__
 *
 * To run a mutation, you first call `useCreateTrackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTrackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTrackMutation, { data, loading, error }] = useCreateTrackMutation({
 *   variables: {
 *      title: // value for 'title'
 *      project: // value for 'project'
 *   },
 * });
 */
export function useCreateTrackMutation(baseOptions?: Apollo.MutationHookOptions<CreateTrackMutation, CreateTrackMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTrackMutation, CreateTrackMutationVariables>(CreateTrackDocument, options);
      }
export type CreateTrackMutationHookResult = ReturnType<typeof useCreateTrackMutation>;
export type CreateTrackMutationResult = Apollo.MutationResult<CreateTrackMutation>;
export type CreateTrackMutationOptions = Apollo.BaseMutationOptions<CreateTrackMutation, CreateTrackMutationVariables>;