import create from 'zustand';
import { v4 as uuid } from 'uuid';
import { getApolloClient } from 'common/hooks/useApollo';
import {
  BaseTrackFragment,
  BaseTrackFragmentDoc
} from 'track/fragments/baseTrack';
import {
  FileUploadPurpose,
  RevisionColors,
  RevisionDynamics
} from 'common/types/graphql-types';
import { Upload, useUploadsStore } from 'common/state/uploads';
import {
  CreateTrackDocument,
  CreateTrackMutation,
  CreateTrackMutationVariables
} from 'track/mutations/createTrack';
import {
  CreateRevisionDocument,
  CreateRevisionMutation,
  CreateRevisionMutationVariables
} from 'track/mutations/createRevision';
import { MeDocument } from 'user/queries/me';
import { useAnalytics } from 'common/hooks/useAnalytics';
import { REVISION_UPLOADED, TRACK_CREATED } from 'track/events';

export interface AssociatedTrack {
  id?: string;
  title?: string;
}

export interface Revision {
  id: string;
  name: string;
  track: AssociatedTrack;
  file: File;
  upload?: string;
  project?: string;
  masteringEnabled?: boolean;
  useLegacyEngine?: boolean;
  settings?: {
    colors: RevisionColors;
    dynamics: RevisionDynamics;
  };
}

type UpdateRevisionInput = Pick<Revision, 'id'> & Partial<Revision>;

export interface RevisionUploadState {
  validInputs: boolean;
  revisions: Record<string, Revision>;
  addRevisions: (files: File[]) => Record<string, Revision>;
  reset: () => void;
  updateRevision: (revision: Pick<Revision, 'id'> & Partial<Revision>) => void;
  removeRevision: (id: string) => void;
  uploadRevisions: (project: string) => Promise<void>;
}

const DEFAULT_REVISIONS = {
  track: {},
  masteringEnabled: true,
  useLegacyEngine: false,
  name: ''
};

export const useRevisionUploadStore = create<RevisionUploadState>(
  (set, get) => {
    const analytics = useAnalytics();

    const validateInputs = (revisions: Record<string, Revision>) =>
      !Object.values(revisions).some(
        ({ track }) => !track?.id && !track?.title
      );

    const updateRevision = ({ id, track, ...rest }: UpdateRevisionInput) => {
      const apollo = getApolloClient({});
      const revisions = get().revisions;
      const existing = revisions?.[id] || {};
      const _track = { ...(track || existing.track) };

      if (track?.id) {
        const data = apollo.readFragment<BaseTrackFragment>({
          id: `Track:${track.id}`,
          fragment: BaseTrackFragmentDoc
        });
        if (!data) {
          throw Error('Associated track does not exist');
        }

        _track.title = data.title;
      }

      const newRevisions = {
        ...revisions,
        [id]: { ...existing, track: _track, ...rest, id }
      };
      const validInputs = validateInputs(newRevisions);

      set({
        revisions: newRevisions,
        validInputs
      });
    };

    const addRevisions = (files: File[]) => {
      const existing = get().revisions;
      const addedRevisions: typeof existing = {};

      for (const file of files) {
        const id = uuid();
        addedRevisions[id] = { file, id, ...DEFAULT_REVISIONS };
      }
      const revisions = { ...existing, ...addedRevisions };
      const validInputs = validateInputs(revisions);

      set({ revisions, validInputs });
      return addedRevisions;
    };

    const reset = () => {
      const revisions = { ...get().revisions };

      for (const revision of Object.values(revisions)) {
        if (revision.upload) {
          continue;
        }
        delete revisions[revision.id];
      }

      set({ validInputs: true, revisions });
    };
    const removeRevision = (id: string) => {
      const revisions = { ...get().revisions };
      delete revisions?.[id];

      set({ revisions });
    };

    const uploadRevisions = async (project: string) => {
      const { uploadFile } = useUploadsStore.getState();
      const revisions = get().revisions;
      const trackTitleMap: Record<string, string> = {};
      const revisionsToUpdate: UpdateRevisionInput[] = [];

      await Promise.all(
        Object.values(revisions).map(
          async ({
            id,
            upload: existingUpload,
            file,
            name,
            settings,
            track,
            masteringEnabled,
            useLegacyEngine
          }) => {
            if (existingUpload) {
              return;
            }

            const onError = () => {
              setTimeout(() => removeRevision(id), 100);
            };
            const onComplete = async (upload: Upload) => {
              const apollo = getApolloClient({});
              if (!settings || !upload?.filename || !project) {
                return;
              }
              if (track.title && trackTitleMap?.[track.title]) {
                track.id = trackTitleMap?.[track.title];
              }

              if (!track.id && track.title) {
                const { data } = await apollo.mutate<
                  CreateTrackMutation,
                  CreateTrackMutationVariables
                >({
                  mutation: CreateTrackDocument,
                  variables: {
                    title: track.title,
                    project
                  }
                });
                if (!data?.createTrack.track) {
                  return;
                }

                analytics.capture(TRACK_CREATED, {
                  id: data?.createTrack?.track?.id,
                  title: data?.createTrack?.track?.title,
                  project: data?.createTrack?.track?.album?.id
                });
                track.id = data?.createTrack.track.id;
                trackTitleMap[track.title] = track.id;
              }

              if (!track.id) {
                throw Error('No track id assigned for upload');
              }

              const { data } = await apollo.mutate<
                CreateRevisionMutation,
                CreateRevisionMutationVariables
              >({
                mutation: CreateRevisionDocument,
                variables: {
                  name,
                  settings: masteringEnabled ? settings : null,
                  original: upload.filename,
                  track: track.id,
                  bypass: !masteringEnabled,
                  useV2Engine: analytics?.isFeatureEnabled('v2_engine_beta') ? !useLegacyEngine : null
                }
              });

              analytics.capture(REVISION_UPLOADED, {
                revision: data?.createRevision?.revision?.id,
                name: data?.createRevision?.revision?.name,
                track: track.id,
                fileName: file.name,
                fileSize: file.size / 1024 / 1024,
                settings: masteringEnabled ? settings : null,
                bypass: !masteringEnabled
              });
              await apollo.query({
                query: MeDocument,
                fetchPolicy: 'network-only'
              });
              removeRevision(id);
            };
            const upload = await uploadFile({
              file: file,
              purpose: FileUploadPurpose.Track,
              onComplete,
              onError
            });
            revisionsToUpdate.push({ id, upload });
            return;
          }
        )
      );

      for (const toUpdate of revisionsToUpdate) {
        updateRevision(toUpdate);
      }

      return;
    };

    return {
      validInputs: true,
      revisions: {},
      addRevisions,
      reset,
      updateRevision,
      removeRevision,
      uploadRevisions
    };
  }
);
