export const REVISION_STATUS_TEXT = {
  CREATED: 'Waiting to process',
  UPLOADED: 'Waiting to process',
  DYNAMICS: 'Analyzing Dynamic structure',
  SPECTRAL: 'Analyzing spectral structure',
  BALANCING: 'Applying balance changes',
  MASTERED: 'Processed',
  LOCKED: 'Processed',
  BYPASS: 'Processed',
  INVALID: 'Invalid file'
};

export const REVISION_STATUS_VALUE = {
  CREATED: 0,
  UPLOADED: 0,
  DYNAMICS: 25,
  SPECTRAL: 50,
  BALANCING: 75,
  MASTERED: 100,
  LOCKED: 100,
  BYPASS: 100,
  INVALID: 0
};
